import trackPixels from 'utility/pixel-tracker';

const completedPixels = item => item.onVideoCompleteTrackingPixels;
const impressionPixels = item => item.onImpressionTrackingPixels;
const sharePixels = item => item.onShareTrackingPixels;
const reactionPixels = item => item.onReactionTrackingPixels;
const quartilesPixelMap = [
  item => item.onPlayTrackingPixels,
  item => item.onVideoFirstQuartileTrackingPixels,
  item => item.onVideoMidpointQuartileTrackingPixels,
  item => item.onVideoThirdQuartileTrackingPixels,
];

function _getPixels(item, fetchPixels) {
  return fetchPixels(item) || [];
}

function _trackPixel(item, fetchPixels) {
  trackPixels(_getPixels(item, fetchPixels));
}

class PixelLogger {
  share(communityContent) {
    _trackPixel(communityContent, sharePixels);
  }

  impression(communityContent) {
    _trackPixel(communityContent, impressionPixels);
  }

  videoQuartileViewed(communityContent, quartile) {
    _trackPixel(communityContent, quartilesPixelMap[quartile]);
  }

  videoCompleted(communityContent) {
    _trackPixel(communityContent, completedPixels);
  }

  reaction(communityContent) {
    _trackPixel(communityContent, reactionPixels);
  }
}

export default PixelLogger;
