const MINIMUM_VIDEO_PLAY_TIME_MS = 2000;

function _playedQuartile(quartiles, quartile) {
  return quartiles.map((value, index) => {
    return index === quartile ? true : value;
  });
}

class VideoPlaybackHandler {
  constructor(logger, communityContent) {
    this.logger = logger;
    this.communityContent = communityContent;
    this._resetLogging();
  }

  _resetLogging() {
    this.isCompleted = false;
    this.durationWatchedMs = 0;
    this.lastUpdatedLogMs = new Date();
    this.quartiles = [
      false,
      false,
      false,
      false,
    ];
  }

  _extendLog(logData) {
    return Object.assign({
      isVideoComplete: this.isCompleted,
      durationWatchedInSeconds: this.durationWatchedMs / 1000.0,
    }, logData);
  }

  _updateQuartiles(positionMs, durationMs) {
    const quartileMs = durationMs / 4;
    for (let quartile = 0; quartile <= 4 && positionMs > (quartile * quartileMs); quartile++) {
      if (!this.quartiles[quartile]) {
        this.quartiles = _playedQuartile(this.quartiles, quartile);
      }
    }
  }

  _notifyQuartilesChanged(previousQuartiles, logData) {
    for (let quartile = 0; quartile <= 4; quartile++) {
      if (!previousQuartiles[quartile] && this.quartiles[quartile]) {
        this.logger.videoQuartileViewed(this.communityContent, quartile, this._extendLog(logData));
      }
    }
  }

  play(positionMs, durationMs, logData) {
    if (this.isCompleted) {
      this._resetLogging();
    }

    this.timeUpdated(positionMs, durationMs, logData);
  }

  timeUpdated(positionMs, durationMs, logData, completing = false) {
    // durationWatched is incremented on timeupdate to handle the user skipping through video
    const nowMs = Date.now();
    this.durationWatchedMs += (nowMs - this.lastUpdatedLogMs);
    this.lastUpdatedLogMs = nowMs;

    if ((positionMs < MINIMUM_VIDEO_PLAY_TIME_MS && !completing) || (this.isVideoComplete && !completing)) {
      return;
    }

    const previousQuartiles = this.quartiles;
    this._updateQuartiles(positionMs, durationMs);

    if (this.quartiles !== previousQuartiles) {
      this._notifyQuartilesChanged(previousQuartiles, logData);
    }
  }

  stopped(positionMs, durationMs, logData) {
    this.logger.videoStopped(this.communityContent, this._extendLog(logData));
  }

  completed(positionMs, durationMs, logData) {
    this.isCompleted = true;
    this.timeUpdated(positionMs, durationMs, logData, true);
    this.stopped(positionMs, durationMs, logData);
    this.logger.videoCompleted(this.communityContent, this._extendLog(logData));
  }
}

export default VideoPlaybackHandler;
