const instanceSymbol = Symbol('unloadUtility.instanceSymbol');

class UnloadUtility {
  constructor(symbol) {
    if (process.env.NODE_ENV !== 'production') {
      if (instanceSymbol !== symbol) {
        console.warn('Use UnloadUtility.instance.'); // eslint-disable-line no-console
      }
    }

    if (instanceSymbol === symbol) {
      this.ensureSetup();
    }
  }

  ensureSetup() {
    if (this.isSetup) return;
    this.isSetup = true;
    this.eventHandlers = [];
    window.addEventListener('beforeunload', this.onUnload.bind(this));
    this.nextId = 1;
    // This is primarily for components without a central store
    // Obviously this kind of state is undesirable, but unload
    // handlers are rare enough that the complexity can be managed
    this.globalState = {};
  }

  onUnload(e) {
    for (const eventHandler of this.eventHandlers) {
      const {
        handler,
      } = eventHandler;
      if (handler) {
        handler(e, this.globalState);
      }
    }
  }

  attachHandler(handler, initialState) {
    if (!this.isFunction(handler)) {
      throw new Error('handler must be a function');
    }
    const id = this.nextId;

    this.eventHandlers.push({
      handler,
      id,
    });

    this.globalState = {
      ...this.globalState,
      ...(initialState || {}),
    };

    this.nextId++;
    return id;
  }

  setState(nextState) {
    this.globalState = {
      ...this.globalState,
      ...(nextState || {}),
    };
  }

  isFunction(functionToCheck) {
    const getType = {};
    return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
  }

  static get instance() {
    if (!this[instanceSymbol]) {
      this[instanceSymbol] = new UnloadUtility(instanceSymbol);
    }
    return this[instanceSymbol];
  }
}

export default UnloadUtility;
