/* eslint-disable max-len */
// Should be kept in sync with https://github.com/hudl/hudl-recommendations/blob/master/Hudl.Recommendations.Client/Enum/CommunityVideoRecommendationReasonType.cs
// Note: Enums should map to their (String) name.
export default {
  Unknown: 'Unknown',
  SameUser: 'SameUser',
  Popular: 'Popular',
  Manual: 'Manual',
  UserBased: 'UserBased',
  ContentBased: 'ContentBased',
  RecruitSchool: 'RecruitSchool',
  OverallTrending: 'OverallTrending',
  PersonalizedTrending: 'PersonalizedTrending',
  UserBasedHighlights: 'UserBasedHighlights',
  UserBasedHudlOriginals: 'UserBasedHudlOriginals',
  ContentBasedHighlights: 'ContentBasedHighlights',
  ContentBasedHudlOriginals: 'ContentBasedHudlOriginals',
};
