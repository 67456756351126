import 'whatwg-fetch';
import CommunityContentLogger from 'utility/community-content-logger';

const _gaq = window._gaq || [];
const SharingDialogs = {
  /*
      Documentation: https://developers.facebook.com/docs/reference/plugins/share-links/
      pageUrl     the url of the page to share
  */
  facebook: (pageUrl, communityContent) => {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(pageUrl),
      'facebook_share_dialog',
      'width=626,height=436');
    _gaq.push(['_trackSocial', 'facebook', 'share', pageUrl]);
    CommunityContentLogger.share(communityContent, 'Facebook');
  },

  /*
      Documentation: https://dev.twitter.com/docs/intents/events
      pageUrl     the url of the page to share
      text        the text of the tweet
      hashTags    comma separated list of hashtags to append to the end of the tweet
  */
  twitter: (pageUrl, text, hashTags, communityContent, skipLogging) => {
    window.open(
      'http://twitter.com/share?url=' + encodeURIComponent(pageUrl) +
        '&text=' + encodeURIComponent(text) +
        '&hashtags=' + hashTags,
      'twitterwindow',
      'height=450, width=550');
    if (!skipLogging) {
      _gaq.push(['_trackSocial', 'twitter', 'tweet', pageUrl]);
      CommunityContentLogger.share(communityContent, 'Twitter');
    }
  },

  /*
      Note: for now, this is just a mailto link.  We're exploring proxying to AddThis or rolling our own mail dialog
      in the future
      pageUrl     the url of the page to share
      subject     subject of the email
      body        body of the email.  If you want line breaks, use %0D%0A
  */
  email: (pageUrl, subject, body, communityContent) => {
    const s = escape(subject);
    const b = `${escape(body)}%0D%0A${escape(pageUrl)}`;

    const href = `mailto:?subject=${s}&body=${b}`;

    window.location.href = href;
    _gaq.push(['_trackSocial', 'email', 'send', pageUrl]);
    CommunityContentLogger.share(communityContent, 'Email');
  },
};

export default SharingDialogs;

// Google Analytics reference: https://developers.google.com/analytics/devguides/collection/gajs/gaTrackingSocial
