class AggregateLogger {

  constructor(loggers) {
    this.loggers = loggers;
  }

  share(communityContent, logData) {
    this.loggers.forEach(item => {
      item.share && item.share(communityContent, logData);
    });
  }

  impression(communityContent, logData) {
    this.loggers.forEach(item => {
      item.impression && item.impression(communityContent, logData);
    });
  }

  impressionList(impressions) {
    this.loggers.forEach(item => {
      item.impressionList && item.impressionList(impressions);
    });
  }

  videoQuartileViewed(communityContent, quartile, logData) {
    this.loggers.forEach(item => {
      item.videoQuartileViewed && item.videoQuartileViewed(communityContent, quartile, logData);
    });
  }

  videoStopped(communityContent, logData) {
    this.loggers.forEach(item => {
      item.videoStopped && item.videoStopped(communityContent, logData);
    });
  }

  videoCompleted(communityContent, logData) {
    this.loggers.forEach(item => {
      item.videoCompleted && item.videoCompleted(communityContent, logData);
    });
  }

  recommendationClicked(communityContentId, logData) {
    this.loggers.forEach(item => {
      item.recommendationClicked && item.recommendationClicked(communityContentId, logData);
    });
  }

  reaction(communityContent, logData) {
    this.loggers.forEach(item => {
      item.reaction && item.reaction(communityContent, logData);
    });
  }
}

export default AggregateLogger;
