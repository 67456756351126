import _ from 'underscore';

import { data, Service } from 'hudl-base';
import { toCommunityContentIdString } from 'common/community-content-id';
import VideoRecommendationReason from 'common/video-recommendation-reason';

const instanceSymbol = Symbol('recommendationsUtility.instanceSymbol');
const REQUESTED_RECOMMENDATION_COUNT = 7;

const shuffleVideoSuggestions = (videos) => {
  if (!(videos && videos.length)) {
    return videos;
  }

  const firstVideo = videos[0];

  // If there isn't a manually recommended video, shuffle the entire list.
  if (firstVideo.reason !== VideoRecommendationReason.Manual) {
    return _.shuffle(videos);
  }

  // If there *is* a manually recommended video, only shuffle the list _after_ the first video.
  const restVideos = videos.slice(1);
  const shuffledRestVideos = _.shuffle(restVideos);

  return [
    firstVideo,
    ...shuffledRestVideos,
  ];
};

class RecommendationsUtility {
  constructor(symbol) {
    if (process.env.NODE_ENV !== 'production') {
      if (instanceSymbol !== symbol) {
        console.warn('Use RecommendationsUtility.instance.'); // eslint-disable-line no-console
      }
    }
  }

  static get instance() {
    if (!this[instanceSymbol]) {
      this[instanceSymbol] = new RecommendationsUtility(instanceSymbol);
    }
    return this[instanceSymbol];
  }

  getVideoSuggestions() {
    if (this.recommendationsResultPromise) {
      return this.recommendationsResultPromise;
    }

    const pageData = data.get('pageData');
    const { communityContentId, gender, sportId: sport, state: videoRegion } = pageData.video;
    const communityContentIdString = toCommunityContentIdString(communityContentId);

    const params = {
      communityContentId: communityContentIdString,
      gender,
      limit: REQUESTED_RECOMMENDATION_COUNT,
      sport,
      videoRegion,
    };

    this.recommendationsResultPromise = Promise.resolve(new Service('recommendedVideos')
      .get(params)
      .then(result => {
        // Shuffling the suggestion list is part of our current A/B testing strategy.
        result.recommendations = shuffleVideoSuggestions(result.recommendations);
        return result;
      }))
      .catch(() => this.recommendationsResultPromise = null);

    return this.recommendationsResultPromise;
  }
}

export default RecommendationsUtility;
