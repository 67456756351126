import SessionViews from 'utility/session-views';

class SessionViewLogger {

  videoQuartileViewed(communityContent, quartile) {
    if (quartile === 0) {
      SessionViews.increment();
    }
  }
}

export default SessionViewLogger;
