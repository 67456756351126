import { errorReporter } from 'hudl-base';
window.onerror = errorReporter.sendError;

import { data, Service, UsageLogEvent } from 'hudl-base';
import { hideLoadingSpinner } from 'utility/spinner-utility';
import { getReferrerRoot } from 'utility/logging-utility';
import PerformanceUtility from './utility/page-load';
import SessionViews from 'utility/session-views';
import generateUuid from 'utility/uuid';
import { map } from 'underscore';
import VastTag from 'utility/vast-tag';
import { fetchAPSVideoBid, parseBidInfo } from 'utility/ads/aps';

import HudlPlayer from 'components/hudlplayer/hudlplayer.base-without-jwplayer';
import CallToAction from 'components/hudlplayer/hudlplayer.call-to-action-v2';
import 'whatwg-fetch';
import Logger from 'utility/community-content-logger';
import CommunityContentLogger from 'utility/community-content-logger';
import SharingDialogs from 'utility/sharing-dialogs';

import './video-page-video.scss';
import dependencyLoader from '../../utility/dependency-loader';

let initialPlayHappened = false;
const communityContent = data.get('communityContent');

/* eslint-disable max-len */
const twitterSvg =
  '<svg class="jw-svg-icon jw-svg-icon-twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160"><path d="M56.8,132.5a75.177,75.177,0,0,0,75.3-75.1V54A53.405,53.405,0,0,0,145,40.5a58.075,58.075,0,0,1-15.4,3.9,27.138,27.138,0,0,0,11.6-14.8A53.038,53.038,0,0,1,124.5,36a25.736,25.736,0,0,0-19.3-8.4A26.12,26.12,0,0,0,78.8,53.4V54a16.5,16.5,0,0,0,.7,5.8,71.966,71.966,0,0,1-54.1-27,23.9,23.9,0,0,0-3.9,13.5A26.043,26.043,0,0,0,33.1,68.2,27.018,27.018,0,0,1,20.9,65v.7A26.15,26.15,0,0,0,42.1,91.4a24.149,24.149,0,0,1-7.1.7,12.625,12.625,0,0,1-5.1-.7,25.657,25.657,0,0,0,24.5,18A53.519,53.519,0,0,1,21.6,121a19.683,19.683,0,0,1-6.4-.7,80.388,80.388,0,0,0,41.6,12.2"></path></svg>';
/* eslint-enable max-len */

async function setupPlayerConfig(pageData, jwConfig, hudlPlayerSettings) {
  const isMobile = data.get('isMobile');
  const isAdBlockingEnabled = document.getElementById('adblock-tester') === null;

  Logger.impression(communityContent);

  if (communityContent.disableAds) {
    jwConfig.enableAds = false;
  }

  if (!isAdBlockingEnabled && jwConfig.enableAds && pageData.ads.preRollAdData) {
    const referrerRoot = getReferrerRoot(document.referrer);

    pageData.ads.preRollAdData.viewCount = SessionViews.get();
    pageData.ads.preRollAdData.referrerRoot = referrerRoot;
    let vastTag;
    if (!jwConfig.vastTag) {
      /* eslint-disable dot-notation */
      vastTag = jwConfig.vastTags['video_VideoPage'];
      if (isMobile && jwConfig.vastTags['video_VideoPage_Mobile']) {
        vastTag = jwConfig.vastTags['video_VideoPage_Mobile'];
      }
      /* eslint-enable dot-notation */
    } else {
      vastTag = jwConfig.vastTag;
      if (isMobile && jwConfig.mobileVastTag) {
        vastTag = jwConfig.mobileVastTag;
      }
    }

    const bid = await fetchAPSVideoBid();
    const bidParams = parseBidInfo(bid);
    pageData.ads.preRollAdData = Object.assign({}, pageData.ads.preRollAdData, bidParams);

    const tag = VastTag(vastTag, pageData.ads.preRollAdData);

    hudlPlayerSettings.setupArgs.advertising = {
      autoplayadsmuted: true,
      client: 'googima',
      tag: tag,
      vpaidcontrols: true,
      vpaidmode: 'enabled',
    };
  }

  const setupLog = new UsageLogEvent({
    logger: 'PublicVideoPage',
    eventName: 'SetupPlayer',
  });
  const setupPlayerData = {
    referrerUrl: document.referrer,
  };

  if (jwConfig.useFlashPlayer) {
    hudlPlayerSettings.setupArgs.primary = 'flash';
    setupPlayerData.player = 'flash';
  } else {
    setupPlayerData.player = 'html5';
  }
  setupLog.log(setupPlayerData);
}

function getCallToActionModel(pageData, uuid) {
  return {
    communityContentId: pageData.video.communityContentId,
    sessionId: uuid,
  };
}

function markTimeToPlayerReady() {
  const videoPageData = data.get('pageData');
  const currentTimeInMilliseconds = PerformanceUtility.getTimeFromLoadInMilliseconds();

  new Service('logPlayerReady').post({
    ownerId: videoPageData.owner.id,
    contentId: videoPageData.video.id,
    ownerType: videoPageData.owner.feedUserType,
    pageLoadInMilliseconds: currentTimeInMilliseconds,
  });
}

function onShare(shareData) {
  const methodMap = {
    embed: 'CopyEmbed',
    link: 'CopyLink',
    facebook: 'Facebook',
    twitter: 'Twitter',
    email: 'Email',
  };

  CommunityContentLogger.share(communityContent, methodMap[shareData.method] || shareData.method);
}

function markTimeToVideoPlay() {
  const videoPageData = data.get('pageData');
  const currentTimeInMilliseconds = PerformanceUtility.getTimeFromLoadInMilliseconds();

  if (initialPlayHappened) {
    return;
  }

  initialPlayHappened = true;

  new Service('logInitialPlay').post({
    ownerId: videoPageData.owner.id,
    contentId: videoPageData.video.id,
    ownerType: videoPageData.owner.feedUserType,
    pageLoadInMilliseconds: currentTimeInMilliseconds,
  });
}

function logNewSessionStart(usePostRollCta, sessionStorageAvailable, uuid) {
  new UsageLogEvent({
    logger: 'PublicVideoPage',
    eventName: 'VideoPageInteraction',
    data: {
      sessionStorageAvailable,
      usePostRollCta,
      op: 'VideoPageSession',
      func: 'Start',
      sessionId: uuid,
    },
  }).log();
}

async function setupPlayer(pageData, sessionData = {}) {
  const video = pageData.video;
  const jwConfig = data.get('jwPlayer');
  const usePostRollCta = sessionData.usePostRollCta || false;
  const isAutoAdvance = sessionData.isAutoAdvance || false;
  const uuid = sessionData.uuid || null;

  const hudlPlayerSettings = {
    autoGenHighlightType: pageData.video.autoGenHighlightType,
    usageLogging: true,
    countSessionViews: true,
    enablePreload: true,
    setupArgs: {
      image: pageData.video.thumbnailUri,
      width: '100%',
      aspectratio: '16:9',
      stretching: 'uniform',
      autostart: 'viewable',
      mute: true,
      sharing: {
        link: pageData.videoSharingInfo.shortenedPageUrl,
        sites: [
          'facebook',
          {
            label: 'twitter',
            svg: twitterSvg, // Undocumented so unknown if this will work for version updates.
            src: link => {
              const tweet =
                pageData.videoSharingInfo.tweetText ||
                `Check out ${pageData.owner.namePossessive} ${pageData.video.title} on @Hudl`;
              SharingDialogs.twitter(link, tweet, 'hudl', communityContent, true);
            },
          },
          'email',
        ],
        code: pageData.videoSharingInfo.embedCode,
      },
      abouttext: pageData.video.title,
      aboutlink: pageData.videoSharingInfo.shortenedPageUrl,
    },
    sessionId: uuid,
    usePostRollCta,
    isAutoAdvance,
  };

  await setupPlayerConfig(pageData, jwConfig, hudlPlayerSettings);

  const player = new HudlPlayer('player', hudlPlayerSettings);
  const callToActionModel = getCallToActionModel(pageData, uuid);

  if (usePostRollCta) {
    player.loadPlugin(new CallToAction(callToActionModel));
  }

  player.onPlay(markTimeToVideoPlay);
  player.onShare(onShare);
  player.onReady(markTimeToPlayerReady);
  player.onReady(() => hideLoadingSpinner('html-player-spinner'));

  const qualityMap = {
    hd: '720p',
    sd: '480p',
    mobile: '360p',
  };
  const sources = map(video.sources, (source, key) => {
    return {
      default: key === 'sd',
      file: source,
      label: qualityMap[key] || key,
    };
  });

  // Make sure we have a video to play
  if (sources.length) {
    player.setPlaylist(
      {
        image: video.thumbnailUri,
        sources: sources,
        communityContentId: video.communityContentId,
      },
      true,
    );
  }

  return function getPlayer() {
    return player;
  };
}

function setupSession() {
  const postRollCtaPercentage = data.getNullable('postRollCtaPercentage') || 0;

  // Configure A/B testing for post-roll suggestion, which we have configured for a percentage of sessions
  let usePostRollCta;
  let uuid;
  // Kill Switch (ignores currently set value in case we need to turn this off immediately)
  if (postRollCtaPercentage === 0) {
    usePostRollCta = false;
    if (window.sessionStorage) {
      try {
        window.sessionStorage.setItem('videoPage.usePostRollCta', JSON.stringify(false));
      } catch (e) {
        // Nothing to be done
      }
    }
  } else {
    const usePostRollIfNotAlreadySet = Math.random() * 100 < postRollCtaPercentage;
    if (window.sessionStorage) {
      const existingValue = window.sessionStorage.getItem('videoPage.usePostRollCta');
      if (existingValue) {
        usePostRollCta = JSON.parse(existingValue);
      } else {
        usePostRollCta = usePostRollIfNotAlreadySet;
        try {
          window.sessionStorage.setItem('videoPage.usePostRollCta', JSON.stringify(usePostRollIfNotAlreadySet));
        } catch (e) {
          // Browser storage is either full or user is private browsing in Mobile Safari
          // To keep consistent throughout the session, going to just set the most probable case
          usePostRollCta = postRollCtaPercentage > 50;
        }
      }

      uuid = window.sessionStorage.getItem('videoPage.sessionId');
      if (!uuid) {
        uuid = generateUuid();
        try {
          window.sessionStorage.setItem('videoPage.sessionId', uuid);
          logNewSessionStart(usePostRollCta, true, uuid);
        } catch (e) {
          // Browser storage is either full or user is private browsing in Mobile Safari
          // To keep consistent throughout the session, going to just set the most probable case
          logNewSessionStart(usePostRollCta, false, uuid);
        }
      }
    } else {
      // If we don't have access to session storage, set the most probable case
      usePostRollCta = postRollCtaPercentage > 50;
      uuid = generateUuid();
      logNewSessionStart(usePostRollCta, false, uuid);
    }
  }

  let isAutoAdvance = false;
  try {
    const isAutoAdvanceJson = window.sessionStorage.getItem('videoPage.isAutoAdvance');
    if (isAutoAdvanceJson) {
      isAutoAdvance = JSON.parse(isAutoAdvanceJson);
    }
    // Clear out the property on every load
    window.sessionStorage.setItem('videoPage.isAutoAdvance', JSON.stringify(false));
  } catch (e) {
    isAutoAdvance = false;
  }

  return {
    uuid,
    usePostRollCta,
    isAutoAdvance,
  };
}

$(function () {
  (async () => {
    const sessionData = setupSession();
    const pageData = data.get('pageData');
    const hudlplayer = await setupPlayer(pageData, sessionData);

    dependencyLoader(1, context => ({
      ...context,
      pageData,
      sessionData,
      hudlPlayer: hudlplayer,
    }));
  })();
});
