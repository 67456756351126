// Utilities for Amazon Publisher Services (APS)
const PUB_ID = '6d74a82d-4d86-4019-a5e4-0b2d8ca66595';
const VIDEO_AD_SERVER = 'DFP';
const SLOT_ID = 'Hudl_Video';
const MEDIA_TYPE = 'video';

export const fetchAPSVideoBid = async () =>
{
    return new Promise((resolve) =>
    {
        try
        {
            window.apstag.fetchBids(
                {
                    slots: [
                        {
                            slotID: SLOT_ID,
                            mediaType: MEDIA_TYPE,
                        },
                    ],
                    timeout: 2000,
                },
                function (bids)
                {
                    if (bids.length === 0)
                    {
                        resolve({});
                    } else
                    {
                        resolve(bids[0]);
                    }
                }
            );
        } catch (e)
        {
            console.error('Error fetching APS video bid', e);
            resolve({});
        }
    });
};

// Create a object with the targeting key value pairs
// that can be inserted into `pageData.ads.preRollAdData`
export const parseBidInfo = (bid) =>
{
    if (!bid || !bid.qsParams) return {};

    const params = {};
    try
    {
        bid.qsParams.split('&').forEach(token =>
        {
            if (token)
            {
                const [key, value] = token.split('=');
                params[key] = value;
            }
        });
    }
    catch (e)
    {
        console.warn('Unable to parse targeting data', e);
        return {};
    }

    return params;
}