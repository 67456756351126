import { data } from 'hudl-base';
import PersonalizationUtility from 'utility/personalization-utility';

const pageData = data.getNullable('pageData') || {};

class PersonalizationLogger {
  videoQuartileViewed(communityContent, quartile) {
    if (quartile === 0 && pageData && pageData.video && pageData.video.teamId) {
      PersonalizationUtility.addRecentTeamId(pageData.video.teamId);
    }
  }
}

export default PersonalizationLogger;
