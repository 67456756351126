import $ from 'jquery';

export default function SVGReplacer() {
  const $img = $(this);
  const imgID = $img.attr('id');
  const imgClass = $img.attr('class');
  let imgURL = $img.attr('src');
  if (imgURL && imgURL.lastIndexOf('http', 0) === -1) {
    const href = window.location.href.split('/');
    imgURL = href[0] + '//' + href[2] + imgURL;
  }

  $.get(imgURL, function getImageHandler(data) {
    // Get the SVG tag, ignore the rest
    let $svg = $(data).find('svg');

    // Add replaced image's ID to the new SVG
    if (typeof imgID !== 'undefined') {
      $svg = $svg.attr('id', imgID);
    }
    // Add replaced image's classes to the new SVG
    if (typeof imgClass !== 'undefined') {
      $svg = $svg.attr('class', imgClass + ' replaced-svg');
    }

    // Remove any invalid XML tags as per http://validator.w3.org
    $svg = $svg.removeAttr('xmlns:a');

    // Replace image with new SVG
    $img.replaceWith($svg);
  }, 'xml');
}
