import { keys } from 'underscore';
import $ from 'jquery';
import pluginTemplate from './templates/hudlplayer-call-to-action-v2.hbs';
import relatedVideoTemplate from './templates/_hudlplayer-call-to-action-related-video.hbs';
import CommunityContentLogger from 'utility/community-content-logger';
import { getReferrerRoot } from 'utility/logging-utility';
import PageVisibilityUtility from 'utility/page-visibility-utility';
import RecommendationsUtility from 'utility/recommendations-utility';
import UnloadUtility from 'utility/unload-utility';
import { data, UsageLogEvent } from 'hudl-base';
import ProgressBar  from 'progressbar.js';
import svgReplace from 'common/svg-replacer';
import { toCommunityContentIdString } from 'common/community-content-id';

import './_hudlplayer-embeddable-call-to-action.scss';

// Constants
const NEXT_VIDEO_COUNTDOWN_SECONDS = 10;

const selectors = {
  container: '.hudlplayer-cta-container',
  replayContainer: '.hudlplayer-cta-replay',
  suggestionContainer: '.hudlplayer-cta-related',
  title: '.hudlplayer-cta-title',
  ownerInfo: '.hudlplayer-cta-athlete-name-position',
  ownerAdditionalInfo: '.hudlplayer-cta-athlete-grad-year',
  playNextButton: '.hudlplayer-cta-play-container',
  ctaUpNextContainer: '.hudlplayer-cta-up-next-container',
  suggestedVideoLink: '.link',
  cancelAutoplayContainer: '.hudlplayer-cta-cancel-container',
  cancelAutoplayButton: '.hudlplayer-cta-cancel-btn',
  playNextCountdown: '.hudlplayer-cta-time',
  playNextCountdownTime: '.remaining-time',
  playNextProgressbar: '.progress-container',
  progressCircle: '#progress-circle',
};
// End Constants

class HudlPlayerCallToAction {
  constructor(ctaVideo) {
    this.ctaVideo = ctaVideo;
    this.currentVideo = null;
    this.recommendationsLoaded = false;
    this.isRecommendationsAvailable = true;
    this.isVisible = false;
    this.autoplayCancelled = false;
    this.suggestionList = [];
    this.deviceInfoForLogging = data.getNullable('deviceInfoForLogging');
    this.playManualSuggestions = data.getNullable('playManualSuggestions') || false;
    this.sessionId = ctaVideo.sessionId;

    this.key = 'call-to-action';
    this.player = null;

    // Function binding
    this.boundHandleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  load(hudlPlayer) {
    const overlayTemplate = pluginTemplate();
    this.player = hudlPlayer;

    hudlPlayer.$el.append(overlayTemplate);

    $('img.svg').each(svgReplace);

    this.$container = $(selectors.container);

    hudlPlayer.onPlay(() => this.onPlay())
      .onAdImpression(() => this.onPlay())
      .onComplete(() => this.onComplete())
      .on('fullscreenChange', () => this.onFullscreen());

    this.currentVideo = this.ctaVideo;
  }

  updateVideo(newVideo) {
    this.currentVideo = newVideo;
  }

  show() {
    this.isVisible = true;
    this.$container.show();
    setTimeout(() => {
      this.$container.addClass('show');
    }, 0);

    this.autoplayCancelled = false;
  }

  hide() {
    this.isVisible = false;
    this.$container.hide();
    this.$container.removeClass('show');
    clearInterval(this.nextVideoTimer);
    if (this.progressCircle) {
      setTimeout(() => {
        this.resetCountdownTimer();
      }, 250); // Comes from the CSS
    }
  }

  resetCountdownTimer() {
    clearInterval(this.nextVideoTimer);
    $(selectors.playNextCountdownTime).text(NEXT_VIDEO_COUNTDOWN_SECONDS);
    this.progressCircle.set(0);
  }

  startCountdownTimer() {
    let countdownTime = NEXT_VIDEO_COUNTDOWN_SECONDS;

    this.progressCircle.animate(1.0, {
      duration: NEXT_VIDEO_COUNTDOWN_SECONDS * 1000,
    });

    $(selectors.playNextProgressbar).removeClass('hidden');
    $(selectors.playNextCountdown).removeClass('hidden');
    $(selectors.cancelAutoplayContainer).removeClass('hidden');

    const recommendationToAutoplayIndex = 0;
    const recommendationToAutoplay = this.suggestionList[recommendationToAutoplayIndex];

    this.nextVideoTimer = setInterval(() => {
      countdownTime--;
      $(selectors.playNextCountdownTime).text(countdownTime);
      if (countdownTime === 0) {
        clearInterval(this.nextVideoTimer);
        UnloadUtility.instance.setState({
          recommendationClicked: true,
        });
        CommunityContentLogger.recommendationClicked(
           recommendationToAutoplay.communityContentId,
           recommendationToAutoplay.reason, true);
        this.playSuggestionIndex(recommendationToAutoplayIndex, true);
      }
    }, 1000);

    // This plugin is hardcoded to support displaying (and autoplaying) a single recommendation.
    //
    // It isn't easy to juggle the various post-roll states (autoplay or replay)
    // with the video page's overall visibilty state,
    // so this ends up being the most convenient place to trigger an event.
    this.player.trigger('post-roll:recommendation-visible', [recommendationToAutoplay]);
  }

  stopCountdownTimer(log = true) {
    if (!this.$container.hasClass('show')) {
      return;
    }

    const remainingTime = $(selectors.playNextCountdownTime).first().text();
    if (log) {
      this.logCancelAutoplay(remainingTime);
    }
    clearInterval(this.nextVideoTimer);
    this.progressCircle.stop();
    $(selectors.playNextProgressbar).addClass('hidden');
    $(selectors.playNextCountdown).addClass('hidden');
    $(selectors.cancelAutoplayContainer).addClass('hidden');
  }

  handleCtaClick() {
    clearInterval(this.nextVideoTimer);
    if (this.suggestionList.length) {
      UnloadUtility.instance.setState({
        recommendationClicked: true,
      });
      CommunityContentLogger.recommendationClicked(this.suggestionList[0].communityContentId,
         this.suggestionList[0].reason,
         false);
      this.playSuggestionIndex(0, false);
    }
  }

  handleSuggestionClick(e) {
    if (e.ctrlKey || e.metaKey || e.shiftKey || e.button === 1) {
      // Let users open a suggested video in a new tab/window.
      return;
    }
    e.preventDefault();

    const suggestionElements = Array.prototype.slice.call($('.related-video'));
    const suggestionIndex = suggestionElements.indexOf(this.parentElement);
    const thisHighlight = this.suggestionList[suggestionIndex];

    clearInterval(this.nextVideoTimer);
    CommunityContentLogger.recommendationClicked(thisHighlight.communityContentId, thisHighlight.reason, false);
    this.playSuggestionIndex(suggestionIndex, false);
  }

  handleCancelAutoplay(e) {
    e.preventDefault();

    this.autoplayCancelled = true;
    this.stopCountdownTimer(true);
    this.hide();
  }

  // This method should only be used as an event handler for `visibilitychange`.
  // Otherwise, unsupported browsers will experience CTA resets any time this method is called.
  handleVisibilityChange() {
    // Ignore visibility changes if the CTA isn't visible or autoplay has been cancelled.
    if (!this.isVisible || this.autoplayCancelled) {
      return;
    }

    if (document.hidden) {
      this.stopCountdownTimer(false);
      return;
    }

    this.resetCountdownTimer();
    this.startCountdownTimer();
  }

  cleanUpCta() {
    this.hide();
    this.player.trigger('post-roll:end');
  }

  // #region Events

  onFullscreen() {
    this.$container.toggleClass('fullscreen');
  }

  onPlay() {
    if (this.isVisible) {
      this.cleanUpCta();
    }

    this.getRecommendations();
  }

  onComplete() {
    this.show();
    this.player.trigger('post-roll:start');
    this.logPostRollStart();
    UnloadUtility.instance.attachHandler(this.onUnload.bind(this));

    if (this.suggestionList.length) {
      if (!document.hidden) {
        this.startCountdownTimer();
      }

      $(selectors.playNextProgressbar).removeClass('hidden');
      $(selectors.playNextCountdown).removeClass('hidden');
      $(selectors.cancelAutoplayContainer).removeClass('hidden');
    } else {
      $(selectors.playNextButton).on('click', this.handleCtaClick.bind(this));
    }
  }

  // #endregion

  // #region Video Key Helper
  getKeyForVideo(video) {
    return video.communityContentId.relatedId;
  }

  // #endregion

  // #region Suggested Video Management

  configureNextVideoScreen(newElements) {
    if (this.suggestionList.length) {
      $(selectors.suggestionContainer).removeClass('hidden');
      $(selectors.replayContainer).addClass('hidden');
      this.addSuggestionsToPostRoll(newElements);
      this.progressCircle.set(0);
    } else {
      $(selectors.replayContainer).removeClass('hidden');
      $(selectors.suggestionContainer).addClass('hidden');
    }
  }

  setAutoplaySuggestion(index) {
    if (index === 0) {
      return;
    }

    const $relatedVideosList = $('.related-videos');
    const $relatedVideos = $relatedVideosList.children();
    const newAutoplay = $($relatedVideos[index]).detach();
    const suggestion = this.suggestionList.splice(index, 1)[0];

    $relatedVideosList.prepend(newAutoplay);
    this.suggestionList.unshift(suggestion);
  }

  addEventHandlersToPostRollElements() {
    const $newElements = $('.related-video.new');

    $newElements.find(selectors.playNextButton).on('click', this.handleCtaClick.bind(this));
    $newElements.find(selectors.suggestedVideoLink).on('click', this.handleSuggestionClick.bind(this));
    $newElements.find(selectors.cancelAutoplayButton).on('click', this.handleCancelAutoplay.bind(this));

    $newElements.removeClass('new');
  }

  addSuggestionsToPostRoll(newElements) {
    if (newElements.length) {
      // Only show the first suggestion. If we decide to display multiple suggestions
      // we'll need to adjust our impression logging.
      $('.related-videos').empty().prepend(newElements.slice(0, 1));
    }

    $('img.svg').each(svgReplace);

    this.addEventHandlersToPostRollElements();

    this.player.on('sharing:show', this.stopCountdownTimer.bind(this, false));
    this.player.on('sharing:hide', () => {
      if (!this.isVisible || this.autoplayCancelled || document.hidden) {
        return;
      }

      this.resetCountdownTimer();
      this.startCountdownTimer();
    });

    document.addEventListener('visibilitychange', this.boundHandleVisibilityChange);

    this.addProgressCircle();
  }

  // There should only be one active progress bar at a time, so it's necessary
  // to add an id to the first related video elements
  addProgressCircle() {
    // First, remove the old progress bar, if it exists.
    const currentCircle = $(selectors.progressCircle);
    if (currentCircle.length) {
      currentCircle.children().remove();
      currentCircle.removeAttr('id');
    }
    const nextProgressCircle = $(selectors.playNextProgressbar).first();
    nextProgressCircle.attr('id', selectors.progressCircle.slice(1));
    this.progressCircle = new ProgressBar.Circle(selectors.progressCircle, {
      color: '#FFFFFF',
      strokeWidth: 4,
    });
  }

  createElementsForNewSuggestions(newSuggestions) {
    const suggestionElements = [];

    for (let i = 0; i < newSuggestions.length; i++) {
      // Adding an experimental post-roll label
      newSuggestions[i].label = 'Recommended For You';

      suggestionElements.push(relatedVideoTemplate(newSuggestions[i]));
    }
    return suggestionElements;
  }

  removeSuggestionAtIndex(index) {
    $('.related-videos').children()[index].remove();
    this.suggestionList.splice(index, 1);
  }

  videoHasPlayableSources(highlight) {
    if (highlight && highlight.videoSources && keys(highlight.videoSources).length) {
      return true;
    }
    return false;
  }

  getRecommendations() {
    const videos = [];
    if (!this.isRecommendationsAvailable || this.recommendationsLoaded) {
      return;
    }

    RecommendationsUtility.instance.getVideoSuggestions().then(response => {
      const recommendations = response.recommendations;
      for (let i = 0; i < recommendations.length; i++) {
        if (this.videoHasPlayableSources(recommendations[i])) {
          if (!this.playManualSuggestions && recommendations[i].reason === 'Manual') {
            // Skip manual for now to avoid always getting pulled into a top 5 loop
            continue;
          }

          videos.push(recommendations[i]);
        }
      }
      this.suggestionList = videos;
      const newElements = this.createElementsForNewSuggestions(videos);
      this.configureNextVideoScreen(newElements);
    }).catch(e => {
      if (e.status === 503) {
        this.isRecommendationsAvailable = false;
      }
    });
  }

  playSuggestionIndex(suggestionIndex, isAutoAdvance) {
    const upNext = this.suggestionList[suggestionIndex];

    if (isAutoAdvance) {
      try {
        window.sessionStorage.setItem('videoPage.isAutoAdvance', JSON.stringify(true));
      } catch (e) {
        console.error(e);
      }
    }

    window.location.href = upNext.relativeUrl;
    this.stopCountdownTimer(false);
  }

  // #endregion

  // #region Logging

  logData(dataToLog) {
    const baseData = {
      referrerUrl: document.referrer,
      referrerRoot: getReferrerRoot(document.referrer),
      version: 'v2',
      origin: 'VideoPage',
      sessionId: this.sessionId,
      usePostRollCta: true,
      ...(this.deviceInfoForLogging || {}),
    };

    const usageLog = new UsageLogEvent({
      logger: 'HudlPlayerCallToAction',
      eventName: 'VideoPageInteraction',
    });

    $.extend(dataToLog, baseData);
    usageLog.log(dataToLog);
  }

  logPostRollStart() {
    if (this.hasLoggedPostRollStart) return;

    const dataToLog = {
      func: 'Start',
      op: 'PostRoll',
      communityContentId: toCommunityContentIdString(this.currentVideo.communityContentId),
      pageVisibility: PageVisibilityUtility.getVisibilityState(),
    };

    this.logData(dataToLog);

    this.hasLoggedPostRollStart = true;
  }

  logCancelAutoplay(timeRemaining) {
    if (this.cancelLogged) return;
    this.cancelLogged = true;
    const dataToLog = {
      func: 'Cancel',
      op: 'Suggestion',
      communityContentId: toCommunityContentIdString(this.currentVideo.communityContentId),
      timeRemaining: timeRemaining,
    };
    this.logData(dataToLog);
  }

  onUnload(e, state) {
    if (state && !state.recommendationClicked) {
      this.stopCountdownTimer(true);
    }
  }

  // #endregion
}

export default HudlPlayerCallToAction;
