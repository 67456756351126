import storageUtility from 'utility/storage-utility';

function SessionViews() {
  const timeToExtendSession = 180 * 60 * 1000; // Every view extends "session" for 3 hours
  const sessionViewsKey = 'session-views';
  this._storeSessionObj = function storeSessionObj(newViews) {
    const objToStore = {
      expiration: new Date().getTime() + timeToExtendSession,
      viewsThisSession: newViews,
    };
    storageUtility.set(sessionViewsKey, JSON.stringify(objToStore));
  };
  this.increment = function increment() {
    const storageItem = storageUtility.get(sessionViewsKey);
    let viewsThisSession = 0;
    if (storageItem) {
      const existingSessionData = JSON.parse(storageItem);
      const hasExpired = new Date().getTime() > existingSessionData.expiration;
      if (!hasExpired) {
        viewsThisSession = existingSessionData.viewsThisSession;
      }
    }
    viewsThisSession++;
    this._storeSessionObj(viewsThisSession);
  };
  this.get = function get() {
    const existingSessionData = storageUtility.get(sessionViewsKey);
    if (existingSessionData) {
      const parsedSessionData = JSON.parse(existingSessionData);
      const hasExpired = new Date().getTime() > parsedSessionData.expiration;
      if (hasExpired) {
        this._storeSessionObj(0);
        return 0;
      }
      return JSON.parse(existingSessionData).viewsThisSession;
    }
    return 0;
  };
}

const sessionViews = new SessionViews();
export default sessionViews;
