import LogTransform from './log-transform';
import AggregateLogger from './aggregate-logger';
import InternalLogger from './internal-logger';
import PixelLogger from './pixel-logger';
import SessionViewLogger from './session-view-logger';
import PersonalizationLogger from './personalization-logger';

export default new LogTransform(
  new AggregateLogger([
    new SessionViewLogger(),
    new InternalLogger(),
    new PixelLogger(),
    new PersonalizationLogger(),
  ])
);
