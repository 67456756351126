import { getBaseProperties } from '../logging-utility';
import { extend } from 'underscore';

class LogTransform {
  constructor(logger) {
    this.logger = logger;
  }

  share(communityContent, network) {
    if (!communityContent.communityContentId) {
      throw new Error('Missing community content ID');
    }

    if (!network) {
      throw new Error('Missing network');
    }

    const logData = extend(getBaseProperties(), {
      network: network,
    });

    this.logger.share && this.logger.share(communityContent, logData);
  }

  impression(communityContent) {
    const logData = getBaseProperties();
    this.logger.impression && this.logger.impression(communityContent, logData);
  }

  videoQuartileViewed(communityContent, quartile, logData) {
    const newLogData = extend(getBaseProperties(), {
      isAutoPlay: logData.isAutoPlay,
      isAutoAdvance: logData.isAutoAdvance,
      isMuted: logData.isMuted,
      playerHeight: logData.playerHeight || 0,
      playerWidth: logData.playerWidth || 0,
      additionalProperties: {
        pageVisibility: logData.pageVisibility || null,
        sessionId: logData.sessionId || null,
        usePostRollCta: logData.usePostRollCta || 'False',
      },
    });

    this.logger.videoQuartileViewed && this.logger.videoQuartileViewed(communityContent, quartile, newLogData);
  }

  videoStopped(communityContent, logData) {
    const newLogData = extend(getBaseProperties(), logData);
    this.logger.videoStopped && this.logger.videoStopped(communityContent, newLogData);
  }

  reaction(communityContent, reaction) {
    const newLogData = extend(getBaseProperties(), { reaction });
    this.logger.reaction && this.logger.reaction(communityContent, newLogData);
  }

  videoCompleted(communityContent, logData) {
    if (!communityContent.communityContentId) {
      throw new Error('Missing community content ID');
    }

    const newLogData = extend(getBaseProperties(), {
      isAutoPlay: logData.isAutoPlay,
      isAutoAdvance: logData.isAutoAdvance,
      isMuted: logData.isMuted,
      playerHeight: logData.playerHeight || 0,
      playerWidth: logData.playerWidth || 0,
      durationWatchedInSeconds: logData.durationWatchedInSeconds,
      isVideoComplete: logData.isVideoComplete,
    });

    this.logger.videoCompleted && this.logger.videoCompleted(communityContent, newLogData);
  }

  recommendationClicked(communityContentId, suggestionReason, isAutoPlay) {
    if (!communityContentId) {
      throw new Error('Missing community content ID');
    }

    const logData = extend(getBaseProperties(), {
      suggestionReason: suggestionReason,
      isAutoPlay: isAutoPlay,
    });

    this.logger.recommendationClicked && this.logger.recommendationClicked(communityContentId, logData);
  }

  impressionList(impressions) {
    const baseProperties = getBaseProperties();
    const transformedImpressions = impressions.map(i => { return { ...baseProperties, ...i }; });

    this.logger.impressionList && this.logger.impressionList(transformedImpressions);
  }
}

export default LogTransform;
