import { Xhr } from 'hudl-base';
import { toCommunityContentIdString } from 'common/community-content-id';

function routeCommunityContentId(communityContent) {
  return toCommunityContentIdString(communityContent.communityContentId);
}

function post(requestUrl, data) {
  Xhr.instance.post(requestUrl, data);
}

const quartiles = [
  'play',
  'view-first-quartile',
  'view-midpoint-quartile',
  'view-third-quartile',
];


class InternalLogger {
  share(communityContent, logData) {
    post('/api/v3/community-content-log/share/' + routeCommunityContentId(communityContent), logData);
  }

  impression(communityContent, logData) {
    post(`/api/v3/community-content-log/impression/${routeCommunityContentId(communityContent)}`, logData);
  }

  impressionList(impressionList) {
    post('/api/v3/community-content-log/impression-list', impressionList);
  }

  videoQuartileViewed(communityContent, quartile, logData) {
    post(`/api/v3/community-content-log/${quartiles[quartile]}/${routeCommunityContentId(communityContent)}`, logData);
  }

  recommendationClicked(communityContentId, logData) {
    const id = toCommunityContentIdString(communityContentId);
    post('/api/v3/community-content-log/recommendation-clicked/' + id, logData);
  }

  videoCompleted(communityContent, logData) {
    post('/api/v3/community-content-log/view-complete/' + routeCommunityContentId(communityContent), logData);
  }

  videoStopped(communityContent, logData) {
    post('/api/v3/community-content-log/stop/' + routeCommunityContentId(communityContent), logData);
  }
}

export default InternalLogger;
