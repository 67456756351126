import $ from 'jquery';

/* eslint-disable max-len */
/*
VastTag:        string
========================
Returns a valid VAST tag that can be sent to an ad server (i.e. DFP)

constructor (baseTag, custParams)
========================
baseTag:        string      The base master video tag with the required URL parameters.
                            It must contain a [referrer_url] and [timestamp] placeholder,
                            which will be replaced with the current page URL and timestamp.

                            VAST tag documentation: https://support.google.com/dfp_premium/answer/1068325?hl=en
                            Example: http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/29795821/PremiumHighlightVideoPlayer&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]

* custParams:   object      (optional) An object mapping keys of custom parameters to their
                            values.  If set, it will be added to the VAST tag with the cust_params
                            parameter for use by the ad server

                            Documentation: https://support.google.com/dfp_premium/answer/1080597
                            Example: { isHudlUser : true, graduationYear : 2012 }
*/
/* eslint-enable max-len */
const VastTag = (baseTag, custParams) => {
  const descriptionUrl = encodeURIComponent(document.URL);
  const referrerUrl = window.location.protocol + '//' + window.location.host;
  const timestamp = encodeURIComponent(new Date().getTime());

  let tag = baseTag.replace('[timestamp]', timestamp)
    .replace('[referrer_url]', referrerUrl)
    .replace('[description_url]', descriptionUrl);

  if (custParams) {
    const params = $.param(custParams);
    // TODO: this may be overkill. the documentation only specifies we replace & = , from the string
    const encoded = encodeURIComponent(params);
    tag = tag + '&cust_params=' + encoded;
  }

  return tag;
};

export default VastTag;
