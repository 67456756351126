import $ from 'jquery';
import _ from 'underscore';
import { data } from 'hudl-base';
import CommunityContentLogger from 'utility/community-content-logger';
import VideoPlaybackHandler from './video-playback-handler';
import hudlPlayerTemplate from './templates/hudlplayer-base.hbs';
// import 'lib/jwplayer';
import * as JwplayerConstants from 'common/jwplayer/jwplayer-constants';
import PageVisibilityUtility from 'utility/page-visibility-utility';
import AdTrackingUtil from 'utility/ad-tracking-utility';

/* eslint-disable no-use-before-define, max-len */
/*
HudlJWPlayer Object:
=========================
*   image:              string,     url for the thumbnail image,
*   sources:            array,      array of file sources for JWPlayer
*   communityContentId: string,     the community content id of the video to be played
otherReelId:    string,     the associated id of the reel to be played
otherOwner:     string,     the associated id of the reel owner
title:          string,     the title for the video playing,
youtube:        bool,       indicates that the video play is from youtube

HudlPlayer plugin:  custom object
=========================
*   key             string,     this has to be a unique key that describes the plugin, also used to retrieve.
*   load            function,   this function will be called when the base player loads the plugin.
*   show            function,   this function should show the custom elements of the plugin.
*   hide            function,   this function should hide the custom elements of the plugin.

constructor (id, options)
=========================
id: the 'id' of the <div> element to target,
options:
usageLogging:   bool,       indicates if the logging feature should be on or off.  If set to true, a
ViewTrackingConfiguration object (from HighlightViewTracking.cs) should be
added to the client data as `HighlightViewTracking` [default true]
controls:       bool,       indicates if the controls should be on or off on the player. [default true]
setupArgs:      object,     additional setup arguments for the JW Player instance [default undefined]


setPlaylist (playlist)
=========================
playlist:           array,      an array of HudlJWPlayer playlist objects
object      a single HudlJWPlayer object

=========================
playlist:           array,      an array of HudlJWPlayer playlist objects
object      a single HudlJWPlayer object

loadPlugin (plugin)             specifies a hudlplayer plugin type that should be loaded with the base player.
=========================
plugin              object,     an instance of a HudlPlayer plugin to load into the base player.
*/

function HighlightJWPlayer(id, settings) {
  const defaultConstructorOptions = {
    usageLogging: true,
    controls: true,
    bufferLogging: false,
    bufferLoggingThreshold: 0,
  };

  const self = this;
  const plugins = {};
  let currentPlaylist = [];
  const playerSettings = _.defaults(settings, defaultConstructorOptions);
  const callbacks = {
    'ready': [],
    'play': [],
    'pause': [],
    'error': [],
    'buffer': [],
    'bufferComplete': [],
    'seek': [],
    'time': [],
    'mute': [],
    'volume': [],
    'complete': [],
    'playlistComplete': [],
    'bufferChange': [],
    'setPlaylist': [],
    'adComplete': [],
    'adError': [],
    'adImpression': [],
    'adSkipped': [],
    'qualityReady': [],
    'qualityChange': [],
  };

  const MediaStatus = {
    unknown: 0,
    ready: 1,
    uploading: 2,
    encoding: 4,
    error: 8,
    waiting: 16,
    modifying: 32,
  };
  let setupError = null;

  const communityContent = data.get('communityContent');
  let videoEventHandler = new VideoPlaybackHandler(CommunityContentLogger, communityContent);

  function onVideoEvent(handler) {
    const positionMs = self.player.getPosition() * 1000;
    const durationMs = self.player.getDuration() * 1000;
    handler.bind(videoEventHandler)(positionMs, durationMs, self.getViewLogData());
  }

  function onPlay() {
    onVideoEvent(videoEventHandler.play);
  }

  function onTimeUpdated() {
    onVideoEvent(videoEventHandler.timeUpdated);
  }

  function onComplete() {
    onVideoEvent(videoEventHandler.completed);
  }

  function onStop() {
    onVideoEvent(videoEventHandler.stopped);
  }

  function setupLogging() {
    if (playerSettings.usageLogging) {
      self.on('play', onPlay);
      self.on('complete', onComplete);
      self.on('time', onTimeUpdated);
      window.addEventListener('beforeunload', onStop);
      window.addEventListener('pagehide', onStop);
    }
  }

  function loadHtml() {
    const hudlPlayerData = { jwId: id };
    const hudlPlayerHtml = hudlPlayerTemplate(hudlPlayerData);

    self.$el = $('#' + id);
    if (settings.skin) {
      self.$el.addClass('hudlplayer-skin-' + settings.skin);
    }
    if (hudlPlayerHtml) {
      self.$el.append(hudlPlayerHtml);
    }
  }

  function playerReady() {
    self.trigger('ready');
  }

  function playerStateChange(evt) {
    if (evt.oldstate === JwplayerConstants.BUFFERING_STATE) {
      self.trigger('bufferComplete');
    }

    if (evt.newstate === JwplayerConstants.BUFFERING_STATE) {
      self.trigger('buffer', [evt]);
    } else if (evt.newstate === JwplayerConstants.PAUSED_STATE) {
      self.trigger('pause', [evt]);
    } else if (evt.newstate === JwplayerConstants.PLAYING_STATE) {
      self.trigger('play', [evt]);
    }
  }

  function playerSeekComplete(evt) {
    self.trigger('seek', [evt]);
  }

  function playerCompleted() {
    self.trigger('complete');
  }

  function playerPlaylistItem(evt) {
    self.trigger('playlistItem', [evt]);
  }

  function playerPlaylistCompleted() {
    self.trigger('playlistComplete');
  }

  function playerTimeChange(evt) {
    setTimeout(() => {
      self.trigger('time', [evt]);
    }, 5);
  }

  function playerVolumeChange(evt) {
    self.trigger('volume', [evt]);
  }

  function playerMute(evt) {
    self.trigger('mute', [evt]);
  }

  function playerBufferChange(evt) {
    setTimeout(() => {
      self.trigger('bufferChange', [evt]);
    }, 5);
  }

  function playerQualityReady() {
    self.trigger('qualityReady');
  }

  function playerQualityChange(evt) {
    self.trigger('qualityChange', [evt]);
  }

  function metaDataLoaded(metadata) {
    self.trigger('metaDataLoaded', [metadata]);
  }

  function playerError(msg) {
    console.error('playback error has occured.'); // eslint-disable-line no-console
    console.log(msg); // eslint-disable-line no-console
    self.trigger('error', [msg]);
  }

  function playerSetupError(evt) {
    console.error('setup error has occured.'); // eslint-disable-line no-console
    console.log(evt); // eslint-disable-line no-console
    setupError = evt;
  }

  function adComplete() {
    self.trigger('adComplete');
  }

  function adImpression() {
    self.trigger('adImpression');
  }

  function adError() {
    self.trigger('adError');
  }

  function adSkipped() {
    self.trigger('adSkipped');
  }

  function playerPlaylistItemChanged(evt) {
    self.trigger('playlistItem', [evt]);
  }

  function onShare(method) {
    self.trigger('share', [method]);
  }

  function bindEvents() {
    self.player.on('ready', playerReady);
    self.player.on('pause', playerStateChange);
    self.player.on('play', playerStateChange);
    self.player.on('bufferFull', playerStateChange);
    self.player.on('idle', playerStateChange);
    self.player.on('bufferChange', playerBufferChange);
    self.player.on('seek', playerSeekComplete);
    self.player.on('complete', playerCompleted);
    self.player.on('playlistItem', playerPlaylistItem);
    self.player.on('playlistComplete', playerPlaylistCompleted);
    self.player.on('time', playerTimeChange);
    self.player.on('volume', playerVolumeChange);
    self.player.on('mute', playerMute);
    self.player.on('error', playerError);
    self.player.on('setupError', playerSetupError);
    self.player.on('adImpression', adImpression);
    self.player.on('adComplete', adComplete);
    self.player.on('adError', adError);
    self.player.on('adSkipped', adSkipped);
    self.player.on('playlistItem', playerPlaylistItemChanged);
    self.player.on('levels', playerQualityReady);
    self.player.on('levelsChanged', playerQualityChange);
    self.player.on('meta', metaDataLoaded);

    self.player.on('ready', () => {
      self.player.getPlugin('sharing').on('click', onShare);
    });
  }

  function loadPlayer(playlist) {
    const jwConfig = data.get('jwPlayer');
    if (jwConfig === null) {
      console.error('Could not find a JWPlayer configuration in ClientData.'); // eslint-disable-line no-console
      return;
    }

    // Passing null in jwConfig.skin won't load a skin. Otherwise, if we have a
    // skin name, we'll load the url from jwConfig.skins (Initialized in JwPlayer.cs),
    // or load glow from the JwPlayer CDN by default.
    // TODO: Consider not loading a skin by default instead of loading one from JwPlayer.
    // The HTML5 player, at least, makes an XHR to load the skin, which is overhead/is
    // blocked by some web filters.
    let jwSkinLocation;
    if (jwConfig.skin === null) {
      jwSkinLocation = null;
    } else {
      jwSkinLocation = (jwConfig.skin) ? jwConfig.skins[jwConfig.skin] : 'glow';
    }

    jwplayer.key = jwConfig.licenseKey;

    let args = {
      playlist: playlist,
      html5player: jwConfig.html5Player,
      width: '100%',
      aspectratio: '16:9',
      skin: jwSkinLocation,
      ga: {},
    };

    args = _.extend(args, settings.setupArgs);
    self.player = window.jwplayer('hudl-jwplayer-' + id);
    self.player.setup(args);

    bindEvents();
  }

  function loadPlugins() {
    for (const key in plugins) {
      if (plugins.hasOwnProperty(key)) {
        plugins[key].load(self);
      }
    }
  }

  function initialize(playlist) {
    setupLogging();

    loadHtml();
    loadPlayer(playlist);
    loadPlugins();
  }

  // Used to reload JWPlayer after it fails due to a setup error. If JWPlayer
  // encounters a setup error, it won't load video through self.player.load
  // unless it's set up again with a valid playlist.
  function reloadPlayer(playlist) {
    // calling self.player.remove() fails if the player encounters a setup
    // error, so assume that JWPlayer can take care of itself. There's an
    // undocumented destroyPlayer method that doesn't seem to do anything,
    // so ignoring that for now

    // clear out the setup error so we can tell if setup failed again
    setupError = null;
    // load the player again with the given playlist
    loadPlayer(playlist);
  }

  function changePlaylist(playlist) {
    self.player.load(playlist);
  }

  function normalizePlaylistArg(playlist) {
    if ($.isArray(playlist)) {
      return playlist;
    }
    return [playlist];
  }

  this.getViewLogData = function getViewLogData() {
    const currentPlaylistItem = self.getPlaylistItem();
    const isMuted = self.player.getMute() || (self.player.getVolume() === 0);
    const playerHeight = self.$el.height();
    const playerWidth = self.$el.width();
    const sessionId = playerSettings.sessionId;
    const usePostRollCta = playerSettings.usePostRollCta;
    const isAutoAdvance = playerSettings.isAutoAdvance;

    const logData = {
      adTrackingId: AdTrackingUtil.getAdTrackingIdAndSetCookie(),
      communityContentId: currentPlaylistItem.communityContentId,
      autoGenHighlightType: playerSettings.autoGenHighlightType,
      pageVisibility: PageVisibilityUtility.getVisibilityState(),
      playerHeight: playerHeight,
      playerWidth: playerWidth,
      shortThreshold: true,
      sessionId: sessionId,
      usePostRollCta: usePostRollCta ? 'True' : 'False',
      isAutoPlay: true,
      isAutoAdvance: isAutoAdvance,
      isMuted: isMuted,
    };

    return logData;
  };

  this.$el = null;
  this.player = null;

  this.setPlaylist = function setPlaylist(playlist, ignoreStatus) {
    const normalizedPlaylist = normalizePlaylistArg(playlist);
    currentPlaylist = normalizedPlaylist;

    // TODO: this needs to be removed from hudlplayer.base
    if (!ignoreStatus) { // sources such as public highlights are no longer associated with the source status, so we'll just assume they're good if they exist
      // filter out bad source statuses
      for (const i in currentPlaylist) {
        if (currentPlaylist.hasOwnProperty(i)) {
          currentPlaylist[i].sources = _.where(currentPlaylist[i].sources, {status: MediaStatus.ready});
        }
      }
    }

    videoEventHandler = new VideoPlaybackHandler(CommunityContentLogger, communityContent);

    this.trigger('setPlaylist');

    if (!this.player) {
      initialize(normalizedPlaylist);
    } else if (setupError) {
      reloadPlayer(normalizedPlaylist);
    } else {
      changePlaylist(normalizedPlaylist);
    }
  };

  this.setPlaylistIndex = function setPlaylistIndex(index) {
    if (index >= this.getPlaylist().length) {
      this.player.playlistItem(this.getPlaylist().length - 1); // Play the last one if one requested is too far out
    } else if (index < 0) {
      this.player.playlistItem(0); // Just in case a negative is passed...
    }
    this.player.playlistItem(index); // should no longer break if index is out of range
  };

  this.onReady = (callback) => {
    return self.on('ready', callback);
  };
  this.onPlay = (callback) => {
    return self.on('play', callback);
  };
  this.onPause = (callback) => {
    return self.on('pause', callback);
  };
  this.onBuffer = (callback) => {
    return self.on('buffer', callback);
  };
  this.onBufferComplete = (callback) => {
    return self.on('bufferComplete', callback);
  };
  this.onSeek = (callback) => {
    return self.on('seek', callback);
  };
  this.onComplete = (callback) => {
    return self.on('complete', callback);
  };
  this.onPlaylistItem = (callback) => {
    return self.on('playlistItem', callback);
  };
  this.onPlaylistComplete = (callback) => {
    return self.on('playlistComplete', callback);
  };
  this.onTime = (callback) => {
    return self.on('time', callback);
  };
  this.onVolumeChange = (callback) => {
    return self.on('volume', callback);
  };
  this.onMute = (callback) => {
    return self.on('mute', callback);
  };
  this.onBufferChange = (callback) => {
    return self.on('bufferChange', callback);
  };
  this.onPlaylistItem = (callback) => {
    return self.on('playlistItem', callback);
  };
  this.onSetPlaylist = (callback) => {
    return self.on('setPlaylist', callback);
  };
  this.onError = (callback) => {
    return self.on('error', callback);
  };
  this.onAdComplete = (callback) => {
    return self.on('adComplete', callback);
  };
  this.onAdError = (callback) => {
    return self.on('adError', callback);
  };
  this.onAdImpression = (callback) => {
    return self.on('adImpression', callback);
  };
  this.onAdSkipped = (callback) => {
    return self.on('adSkipped', callback);
  };
  this.onQualityChange = (callback) => {
    return self.on('qualityChange', callback);
  };
  this.onQualityLevels = (callback) => {
    return self.on('qualityReady', callback);
  };
  this.onShare = (callback) => {
    return self.on('share', callback);
  };
  this.destroy = () => {
    // This is supposed to remove event handlers in JWPlayer, but in reality doesn't seem to work.
    if (self.player !== undefined) {
      self.player.remove();
    }

    self.off();
  };
  this.on = (eventName, callback) => {
    if (typeof callback !== 'function') {
      throw new Error('Attempt was made to bind a non-function as a callback.');
    }
    if (!eventName) {
      throw new Error('Attempt was made to bind an empty or non-string as an event.');
    }
    if (!(eventName in callbacks)) {
      callbacks[eventName] = [];
    }

    callbacks[eventName].push(callback);
    return self;
  };
  this.off = (eventName, callback) => {
    if (!(eventName in callbacks)) {
      return self;
    }
    const indexOfCallback = callbacks[eventName].indexOf(callback);
    if (indexOfCallback !== -1) {
      callbacks[eventName].splice(indexOfCallback, 1);
    }
    return self;
  };
  this.trigger = (eventName, args) => {
    if (!(eventName in callbacks)) {
      return self;
    }
    _.each(callbacks[eventName], (c) => {
      c.apply(self, args);
    });
    return self;
  };

  this.loadPlugin = (plugin) => {
    if (!('key' in plugin)) {
      throw new Error('Attempt was made to load an invalid hudlplayer plugin.');
    }
    if (plugin.key in plugins) {
      throw new Error('Attempt to load a hudlplayer plugin that was already loaded: ' + plugin.key + '.');
    }

    plugins[plugin.key] = plugin;
    return self;
  };
  this.getPlaylist = () => {
    // potentially dangerous since currentPlaylist can be modified externally without using setPlaylist
    return currentPlaylist;
  };
  this.getPlaylistItem = (index) => {
    const itemIndex = index || self.getPlaylistIndex();
    return currentPlaylist[itemIndex];
  };
  this.getPlaylistIndex = () => {
    let index = self.player.getPlaylistIndex();
    if (!index) {
      index = 0; // this would be the case pre jwplayer load
    }
    return index;
  };
  this.getDuration = () => {
    return this.player.getDuration();
  };
  this.getPlayer = () => {
    return this.player;
  };
  this.getPosition = () => {
    return self.player.getPosition();
  };
  this.getMute = () => {
    return this.player.getMute();
  };
  this.getVolume = () => {
    return this.player.getVolume();
  };
}

export default HighlightJWPlayer;
