window.videoPagedependencies = window.videoPagedependencies || { position: 0, callbacks: {}, context: {} };
const dependencies = window.videoPagedependencies;

export default (order, callback) => {
  const { callbacks, position } = dependencies;
  callbacks[order] = callback;
  for (let i = position; i < Object.keys(callbacks).length; i++) {
    if (callbacks[i]) {
      dependencies.context = callbacks[i](dependencies.context);
      dependencies.position = i + 1;
    } else {
      break;
    }
  }
};
