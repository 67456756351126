// Events
export const READY_EVENT = 'ready';
export const PAUSE_EVENT = 'pause';
export const PLAY_EVENT = 'play';
export const BUFFER_CHANGE_EVENT = 'bufferChange';
export const IDLE_EVENT = 'idle';
export const SEEK_EVENT = 'seek';
export const COMPLETE_EVENT = 'complete';
export const PLAYLIST_ITEM_EVENT = 'playlistItem';
export const PLAYLIST_COMPLETE_EVENT = 'playlistComplete';
export const TIME_EVENT = 'time';
export const VOLUME_EVENT = 'volume';
export const MUTE_EVENT = 'mute';
export const ERROR_EVENT = 'error';
export const SETUP_ERROR_EVENT = 'setupError';
export const AD_IMPRESSION_EVENT = 'adImpression';
export const AD_COMPLETE_EVENT = 'adComplete';
export const AD_ERROR_EVENT = 'adError';
export const AD_SKIPPED_EVENT = 'adSkipped';
export const LEVELS_EVENT = 'levels';
export const QUALITY_CHANGE_EVENT = 'qualityChange';
export const META_EVENT = 'meta';

// Player State
export const BUFFERING_STATE = 'buffering';
export const PLAYING_STATE = 'playing';
export const PAUSED_STATE = 'paused';
