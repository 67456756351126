function requestTrackingPixels(pixels = []) {
  const timestampPlaceholder = '[timestamp]';

  if (pixels && pixels.length) {
    const timestamp = (new Date()).getTime();
    for (let trackingPixel of pixels) {
      try {
        if (trackingPixel && trackingPixel.length) {
          trackingPixel = trackingPixel.replace(timestampPlaceholder, timestamp);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = trackingPixel;
          document.body.appendChild(iframe);
        }
      } catch (e) {
          // Nothing to do
      }
    }
  }
}

export default requestTrackingPixels;
